import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/imgs/about/about_top.svg'
import _imports_1 from '../assets/imgs/about/code_gzy.svg'
import _imports_2 from '../assets/imgs/about/code_dzw.svg'


const _hoisted_1 = { class: "price-top" }
const _hoisted_2 = { style: {"width":"75%"} }
const _hoisted_3 = { style: {"height":"200px","display":"flex","align-items":"center","justify-content":"center"} }
const _hoisted_4 = { style: {"width":"100%","height":"80px","text-align":"center","display":"flex","align-items":"center","justify-content":"center","background-color":"#F5F5F5"} }
const _hoisted_5 = { style: {"width":"75%"} }
const _hoisted_6 = { style: {"text-align":"left","height":"80px","display":"flex","align-items":"center"} }
const _hoisted_7 = { style: {"width":"100%","height":"480px","text-align":"center","display":"flex","align-items":"center","justify-content":"center","background-color":"#F5F5F5"} }
const _hoisted_8 = { style: {"width":"75%","background-color":"#fff","border-radius":"5px"} }
const _hoisted_9 = { style: {"background-color":"#fff","height":"150px","display":"grid","margin-left":"20px"} }
const _hoisted_10 = { style: {"width":"100%","text-align":"left","display":"flex","align-items":"center","justify-content":"left"} }
const _hoisted_11 = { style: {"width":"100%","text-align":"left","display":"flex","align-items":"center","justify-content":"left"} }
const _hoisted_12 = { style: {"width":"100%","text-align":"left","display":"flex","align-items":"center","justify-content":"left"} }
const _hoisted_13 = { style: {"background-color":"#fff","height":"150px","display":"grid","margin-left":"20px"} }
const _hoisted_14 = { style: {"width":"100%","text-align":"left","display":"flex","align-items":"center","justify-content":"left"} }
const _hoisted_15 = { style: {"width":"100%","text-align":"left","display":"flex","align-items":"center","justify-content":"left"} }
const _hoisted_16 = { style: {"width":"100%","text-align":"left","display":"flex","align-items":"center","justify-content":"left"} }
const _hoisted_17 = { style: {"background-color":"#fff","height":"60px","display":"grid","font-weight":"800","margin-left":"20px"} }
const _hoisted_18 = { style: {"width":"100%","text-align":"left","display":"flex","align-items":"center","justify-content":"left"} }
const _hoisted_19 = { style: {"width":"100%","height":"80px","text-align":"center","display":"flex","align-items":"center","justify-content":"center","background-color":"#F5F5F5"} }
const _hoisted_20 = { style: {"width":"75%"} }
const _hoisted_21 = { style: {"text-align":"left","height":"80px","display":"flex","align-items":"center"} }
const _hoisted_22 = { style: {"width":"100%","height":"200px","text-align":"center","display":"flex","align-items":"center","justify-content":"center"} }
const _hoisted_23 = { style: {"width":"75%"} }

import {
  IdcardFilled,
  MailFilled,
  PhoneFilled,
  ToolFilled,
  AppstoreFilled,
  StarFilled,
  EnvironmentFilled
} from '@ant-design/icons-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LinkUs',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("div", { style: {"height":"180px","display":"flex","align-items":"center","justify-content":"center"} }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    height: "120px"
                  })
                ], -1)
              ])),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_a_typography_title, {
                    level: 4,
                    style: {"color":"#fff"}
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("诚心诚意做事，认认真真做产品")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 10 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_typography_title, {
                    level: 4,
                    style: {"color":"#1F39A9"}
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("联系我们")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_unref(IdcardFilled), { style: {"color":"#1F39A9"} }),
                    _cache[3] || (_cache[3] = _createTextVNode("    高经理 "))
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_unref(PhoneFilled), { style: {"color":"#1F39A9"} }),
                    _cache[4] || (_cache[4] = _createTextVNode("    180-6825-2929 "))
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_unref(MailFilled), { style: {"color":"#1F39A9"} }),
                    _cache[5] || (_cache[5] = _createTextVNode("    gaozy@gdycloud.com "))
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("div", { style: {"background-color":"#fff","display":"flex","align-items":"center","justify-content":"center","margin":"20px 20px 20px 20px"} }, [
                  _createElementVNode("div", { style: {"width":"100%","text-align":"center","display":"flex","align-items":"center","justify-content":"right"} }, [
                    _createElementVNode("img", {
                      src: _imports_1,
                      height: "120px"
                    })
                  ])
                ], -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_divider),
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_unref(IdcardFilled), { style: {"color":"#1F39A9"} }),
                    _cache[7] || (_cache[7] = _createTextVNode("    董经理 "))
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_unref(PhoneFilled), { style: {"color":"#1F39A9"} }),
                    _cache[8] || (_cache[8] = _createTextVNode("    156-9216-6749 "))
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_unref(MailFilled), { style: {"color":"#1F39A9"} }),
                    _cache[9] || (_cache[9] = _createTextVNode("    dongzw@gdycloud.com "))
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("div", { style: {"background-color":"#fff","display":"flex","align-items":"center","justify-content":"center","margin":"20px 20px 20px 20px"} }, [
                  _createElementVNode("div", { style: {"width":"100%","text-align":"center","display":"flex","align-items":"center","justify-content":"right"} }, [
                    _createElementVNode("img", {
                      src: _imports_2,
                      height: "120px"
                    })
                  ])
                ], -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_divider),
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_unref(EnvironmentFilled), { style: {"color":"#DE7A44"} }),
                    _cache[11] || (_cache[11] = _createTextVNode("    无锡市梁溪区崇安寺街道中山路333号-12G "))
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 4 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_21, [
                  _createVNode(_component_a_typography_title, {
                    level: 4,
                    style: {"color":"#1F39A9"}
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("我们提供的服务")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_component_a_row, { gutter: [15,15] }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 8 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_card, { title: "产品二次开发" }, {
                  extra: _withCtx(() => [
                    _createVNode(_unref(ToolFilled), { style: {"color":"#DE7A44"} })
                  ]),
                  default: _withCtx(() => [
                    _cache[13] || (_cache[13] = _createElementVNode("div", { style: {"text-align":"center","height":"100px","font-size":"16px"} }, " 按照企业特定需要，对产品进行二次开发，满足企业使用需求！ ", -1))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 8 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_card, { title: "办公软件开发" }, {
                  extra: _withCtx(() => [
                    _createVNode(_unref(AppstoreFilled), { style: {"color":"#DE7A44"} })
                  ]),
                  default: _withCtx(() => [
                    _cache[14] || (_cache[14] = _createElementVNode("div", { style: {"text-align":"center","height":"100px","font-size":"16px"} }, " 丰富的项目实施经验，开发企业个性化办公软件! ", -1))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 8 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_card, { title: "优质高效的售后服务" }, {
                  extra: _withCtx(() => [
                    _createVNode(_unref(StarFilled), { style: {"color":"#DE7A44"} })
                  ]),
                  default: _withCtx(() => [
                    _cache[15] || (_cache[15] = _createElementVNode("div", { style: {"text-align":"center","height":"100px","font-size":"16px"} }, " 提供7x24的售后服务，及时解决您的各类问题！ ", -1))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}
}

})