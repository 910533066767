import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/imgs/down/down_top.svg'
import _imports_1 from '../assets/imgs/down/ios_app.png'
import _imports_2 from '../assets/imgs/down/android_app.png'


const _hoisted_1 = { class: "price-top" }
const _hoisted_2 = { style: {"width":"75%"} }
const _hoisted_3 = { class: "download-top" }
const _hoisted_4 = { style: {"width":"75%"} }

import { ref } from 'vue';
import {
  AppleFilled,AndroidFilled,ChromeFilled
} from '@ant-design/icons-vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'DownloadFile',
  setup(__props) {

const activeKey = ref('1');
const listData: Record<string, string>[] = [];

for (let i = 0; i < 23; i++) {
  listData.push({
    href: 'https://www.antdv.com/',
    title: `ant design vue part ${i}`,
    avatar: 'https://joeschmoe.io/api/v1/random',
    description:
      'Ant Design, a design language for background applications, is refined by Ant UED Team.',
    content:
      'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
  });
}

return (_ctx: any,_cache: any) => {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("div", { style: {"height":"180px","display":"flex","align-items":"center","justify-content":"center"} }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    height: "120px"
                  })
                ], -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_tabs, {
          activeKey: activeKey.value,
          "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((activeKey).value = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tab_pane, {
              key: "1",
              tab: "全部"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { gutter: [15,15] }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_card, { title: "苹果APP下载" }, {
                          extra: _withCtx(() => [
                            _createVNode(_unref(AppleFilled), { style: {"color":"#DE7A44"} })
                          ]),
                          default: _withCtx(() => [
                            _cache[2] || (_cache[2] = _createElementVNode("div", { style: {"text-align":"center"} }, [
                              _createElementVNode("p", null, [
                                _createElementVNode("img", {
                                  src: _imports_1,
                                  height: "120px"
                                })
                              ]),
                              _createElementVNode("p", null, [
                                _createElementVNode("a", null, "扫一扫安装苹果APP")
                              ])
                            ], -1))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_card, { title: "安卓APP下载" }, {
                          extra: _withCtx(() => [
                            _createVNode(_unref(AndroidFilled), { style: {"color":"#DE7A44"} })
                          ]),
                          default: _withCtx(() => [
                            _cache[3] || (_cache[3] = _createElementVNode("div", { style: {"text-align":"center"} }, [
                              _createElementVNode("p", null, [
                                _createElementVNode("img", {
                                  src: _imports_2,
                                  height: "120px"
                                })
                              ]),
                              _createElementVNode("p", null, [
                                _createElementVNode("a", null, "扫一扫安装安卓APP")
                              ])
                            ], -1))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_card, { title: "谷歌浏览器下载" }, {
                          extra: _withCtx(() => [
                            _createVNode(_unref(ChromeFilled), { style: {"color":"#DE7A44"} })
                          ]),
                          default: _withCtx(() => [
                            _cache[4] || (_cache[4] = _createElementVNode("div", { style: {"text-align":"left","height":"170px"} }, [
                              _createElementVNode("p", null, [
                                _createElementVNode("a", {
                                  href: "http://www.gdycloud.com/down/Chrome_xp.rar",
                                  target: "_break"
                                }, "【微软XP系统版本】点击下载")
                              ]),
                              _createElementVNode("p", null, [
                                _createElementVNode("a", {
                                  href: "http://www.gdycloud.com/down/Chrome_win7.rar",
                                  target: "_break"
                                }, "【微软win7系统版本】点击下载")
                              ]),
                              _createElementVNode("p", null, [
                                _createElementVNode("a", {
                                  href: "http://www.gdycloud.com/down/Chrome_win10.rar",
                                  target: "_break"
                                }, "【微软win10系统版本】点击下载")
                              ])
                            ], -1))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, {
              key: "2",
              tab: "移动APP",
              "force-render": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { gutter: [5,5] }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_card, { title: "苹果APP下载" }, {
                          extra: _withCtx(() => [
                            _createVNode(_unref(AppleFilled), { style: {"color":"#1F39A9"} })
                          ]),
                          default: _withCtx(() => [
                            _cache[5] || (_cache[5] = _createElementVNode("div", { style: {"text-align":"center"} }, [
                              _createElementVNode("p", null, [
                                _createElementVNode("img", {
                                  src: _imports_1,
                                  height: "120px"
                                })
                              ]),
                              _createElementVNode("p", null, [
                                _createElementVNode("a", null, "扫一扫安装苹果APP")
                              ])
                            ], -1))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_card, { title: "安卓APP下载" }, {
                          extra: _withCtx(() => [
                            _createVNode(_unref(AndroidFilled), { style: {"color":"#1F39A9"} })
                          ]),
                          default: _withCtx(() => [
                            _cache[6] || (_cache[6] = _createElementVNode("div", { style: {"text-align":"center"} }, [
                              _createElementVNode("p", null, [
                                _createElementVNode("img", {
                                  src: _imports_2,
                                  height: "120px"
                                })
                              ]),
                              _createElementVNode("p", null, [
                                _createElementVNode("a", null, "扫一扫安装安卓APP")
                              ])
                            ], -1))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, {
              key: "3",
              tab: "浏览器",
              "force-render": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { gutter: [5,5] }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_card, { title: "谷歌浏览器下载" }, {
                          extra: _withCtx(() => [
                            _createVNode(_unref(ChromeFilled), { style: {"color":"#DE7A44"} })
                          ]),
                          default: _withCtx(() => [
                            _cache[7] || (_cache[7] = _createElementVNode("div", { style: {"text-align":"left","height":"170px"} }, [
                              _createElementVNode("p", null, [
                                _createElementVNode("a", {
                                  href: "http://www.gdycloud.com/down/Chrome_xp.rar",
                                  target: "_break"
                                }, "【微软XP系统版本】点击下载")
                              ]),
                              _createElementVNode("p", null, [
                                _createElementVNode("a", {
                                  href: "http://www.gdycloud.com/down/Chrome_win7.rar",
                                  target: "_break"
                                }, "【微软win7系统版本】点击下载")
                              ]),
                              _createElementVNode("p", null, [
                                _createElementVNode("a", {
                                  href: "http://www.gdycloud.com/down/Chrome_win10.rar",
                                  target: "_break"
                                }, "【微软win10系统版本】点击下载")
                              ])
                            ], -1))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["activeKey"])
      ])
    ]),
    _cache[8] || (_cache[8] = _createElementVNode("div", { style: {"width":"100%","height":"280px","text-align":"center","display":"flex","align-items":"center","justify-content":"center","background-color":"#fff"} }, null, -1))
  ], 64))
}
}

})