import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/imgs/price/price_top.svg'
import _imports_1 from '../assets/imgs/price/icbc.svg'
import _imports_2 from '../assets/imgs/price/zfb.svg'


const _hoisted_1 = { class: "price-top" }
const _hoisted_2 = { style: {"width":"75%"} }
const _hoisted_3 = { style: {"height":"200px","display":"flex","align-items":"center","justify-content":"center"} }
const _hoisted_4 = { style: {"width":"100%","height":"80px","text-align":"center","display":"flex","align-items":"center","justify-content":"center","background-color":"#F5F5F5"} }
const _hoisted_5 = { style: {"width":"75%"} }
const _hoisted_6 = { style: {"text-align":"left","height":"80px","display":"flex","align-items":"center"} }
const _hoisted_7 = { style: {"width":"100%","height":"350px","text-align":"center","display":"flex","align-items":"center","justify-content":"center","background-color":"#F5F5F5"} }
const _hoisted_8 = { style: {"width":"75%","background-color":"#fff","border-radius":"5px"} }
const _hoisted_9 = { style: {"background-color":"#fff","align-items":"center","justify-content":"center"} }
const _hoisted_10 = { style: {"width":"100%","height":"80px","text-align":"center","display":"flex","align-items":"center","justify-content":"center","background-color":"#F5F5F5"} }
const _hoisted_11 = { style: {"width":"75%"} }
const _hoisted_12 = { style: {"text-align":"left","height":"80px","display":"flex","align-items":"center"} }
const _hoisted_13 = { style: {"width":"100%","height":"130px","text-align":"center","display":"flex","align-items":"center","justify-content":"center"} }
const _hoisted_14 = { style: {"width":"75%","background-color":"#fff","border-radius":"5px"} }
const _hoisted_15 = { style: {"text-align":"left","margin":"30px 30px 30px 30px"} }
const _hoisted_16 = { style: {"width":"100%","height":"80px","text-align":"center","display":"flex","align-items":"center","justify-content":"center","background-color":"#F5F5F5"} }
const _hoisted_17 = { style: {"width":"75%"} }
const _hoisted_18 = { style: {"text-align":"left","height":"80px","display":"flex","align-items":"center"} }
const _hoisted_19 = { style: {"width":"100%","height":"150px","text-align":"center","display":"flex","align-items":"center","justify-content":"center","background-color":"#F5F5F5"} }
const _hoisted_20 = { style: {"width":"75%","background-color":"#fff","border-radius":"5px"} }
const _hoisted_21 = { style: {"width":"100%","height":"150px","text-align":"center","display":"flex","align-items":"center","justify-content":"center","background-color":"#F5F5F5","margin-top":"10px"} }
const _hoisted_22 = { style: {"width":"75%","background-color":"#fff","border-radius":"5px"} }

import { h } from 'vue';
import {
  AppstoreFilled,
  FileTextFilled,
  MoneyCollectFilled,
  IdcardFilled,
  PhoneFilled,
  ReadFilled
} from '@ant-design/icons-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProPrice',
  setup(__props) {

const columns = [
  {
    title: '项目',
    dataIndex: 'item',
    key: 'item',
    align:'center'
  },
  {
    title: '1年价格/优惠价格',
    dataIndex: 'price1',
    key: 'price1',
    align:'center'
  },
  {
    title: '2年价格/优惠价格',
    dataIndex: 'price2',
    key: 'price2',
    align:'center'
  },
  {
    title: '3年价格/优惠价格',
    key: 'price3',
    dataIndex: 'price3',
    align:'center'
  },
  {
    title: '4年价格/优惠价格',
    dataIndex:'price4',
    key: 'price4',
    align:'center'
  },
  {
    title: '5年价格/优惠价格',
    dataIndex:'price5',
    key: 'price5',
    align:'center'
  },
];

const data = [
  {
    item: '销售业务',
    price1: '￥500 / ￥400',
    price2: '￥1000 / ￥800',
    price3: '￥1500 / ￥1200',
    price4: '￥2000 / ￥1600',
    price5: '￥2500 / ￥2000',
  },
  {
    item: '售后业务',
    price1: '￥500 / ￥400',
    price2: '￥1000 / ￥800',
    price3: '￥1500 / ￥1200',
    price4: '￥2000 / ￥1600',
    price5: '￥2500 / ￥2000',
  },
  {
    item: '销售+售后业务',
    price1: '￥1000 / ￥800',
    price2: '￥2000 / ￥1600',
    price3: '￥3000 / ￥2400',
    price4: '￥4000 / ￥3200',
    price5: '￥5000 / ￥4000',
  },
  {
    item: '移动APP',
    price1: '免费',
    price2: '免费',
    price3: '免费',
    price4: '免费',
    price5: '免费',
  },
  {
    item: '7*12小时远程服务',
    price1: '免费',
    price2: '免费',
    price3: '免费',
    price4: '免费',
    price5: '免费',
  },
];

return (_ctx: any,_cache: any) => {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_steps = _resolveComponent("a-steps")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_a_typography_title, {
                    level: 4,
                    style: {"color":"#fff"}
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("最优惠的价格，提供最好的产品服务")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("div", { style: {"height":"180px","display":"flex","align-items":"center","justify-content":"center"} }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    height: "120px"
                  })
                ], -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 10 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_typography_title, {
                    level: 4,
                    style: {"color":"#1F39A9"}
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("产品&服务内容&报价")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_a_table, {
                    columns: columns,
                    size: "large",
                    "data-source": data,
                    pagination: false,
                    "row-class-name": (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                  }, null, 8, ["row-class-name"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 4 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_a_typography_title, {
                    level: 4,
                    style: {"color":"#1F39A9"}
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("开通流程")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_a_steps, {
                    size: "default",
                    current: 6,
                    items: [
                {
                  title: '免费试用',
                  description:'系统初步试用',
                  status: 'finish',
                  icon: h(_unref(AppstoreFilled)),
                },
                {
                  title: '签订合同',
                  // description:'保障双方权益',
                  status: 'finish',
                  icon: h(_unref(FileTextFilled)),
                },
                {
                  title: '汇款',
                  description:'',
                  status: 'finish',
                  icon: h(_unref(MoneyCollectFilled)),
                },
                {
                  title: '开通账号',
                  description:'分配正式账号',
                  status: 'finish',
                  icon: h(_unref(IdcardFilled)),
                },
                {
                  title: '在线培训',
                  description:'',
                  status: 'finish',
                  icon: h(_unref(ReadFilled)),
                },
                {
                  title: '远程支持',
                  description:'',
                  status: 'finish',
                  icon: h(_unref(PhoneFilled)),
                }
              ]
                  }, null, 8, ["items"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 4 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_a_typography_title, {
                    level: 4,
                    style: {"color":"#1F39A9"}
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("汇款账号")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 6 }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("div", { style: {"background-color":"#fff","height":"150px","display":"flex","align-items":"center","justify-content":"center"} }, [
                  _createElementVNode("img", { src: _imports_1 })
                ], -1)
              ])),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 16 }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("div", { style: {"background-color":"#fff","height":"150px","display":"grid","font-weight":"800"} }, [
                  _createElementVNode("div", { style: {"width":"100%","text-align":"center","display":"flex","align-items":"center","justify-content":"right"} }, " 开户银行：中国工商银行股份有限公司无锡梁溪支行 "),
                  _createElementVNode("div", { style: {"width":"100%","text-align":"center","display":"flex","align-items":"center","justify-content":"right"} }, " 开户名称：谷得一软件科技无锡有限公司 "),
                  _createElementVNode("div", { style: {"width":"100%","text-align":"center","display":"flex","align-items":"center","justify-content":"right"} }, " 开户账号：1103020109200913465 ")
                ], -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 6 }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createElementVNode("div", { style: {"background-color":"#fff","height":"150px","display":"flex","align-items":"center","justify-content":"center"} }, [
                  _createElementVNode("img", { src: _imports_2 })
                ], -1)
              ])),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 16 }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("div", { style: {"background-color":"#fff","height":"150px","display":"grid","font-weight":"800"} }, [
                  _createElementVNode("div", { style: {"width":"100%","text-align":"center","display":"flex","align-items":"center","justify-content":"right"} }, "   "),
                  _createElementVNode("div", { style: {"width":"100%","text-align":"center","display":"flex","align-items":"center","justify-content":"right"} }, " 支付宝账号：15900827045 "),
                  _createElementVNode("div", { style: {"width":"100%","text-align":"center","display":"flex","align-items":"center","justify-content":"right"} }, " 支付宝姓名：高振义 ")
                ], -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}
}

})