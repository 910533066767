import axios from "axios";
import { baseURL } from "./baseUrlSet.js";
export function request(config) {
  let newVar = axios.create({
    baseURL: baseURL,
    timeout: 60000,
    headers: {
      "X-Access-Token": "application/json",
      "Content-Type": "application/x-www-form-urlencoded"
    },
    withCredentials: true,
  });

  newVar.interceptors.response.use(
    //请求成功处理
    response => {
      const res = response.data
      if (res.code == 200) {
        return res
      } else if (res.code == 500) {
        console.log(res);
      }
    },
    error => {
      if (JSON.stringify(error).includes('timeout')) {
        console.log("");
      }
    }
  )
  return newVar(config);
}




