<template>
    <div class="price-top">
      <div style="width: 75%;">
      <a-row>
        <a-col :span="24">
          <div style="height: 180px;display: flex;align-items: center;justify-content: center;">
            <img src="../assets/imgs/down/down_top.svg" height="120px">
          </div>
        </a-col>
        <!-- <a-col :span="12">
          <div style="height: 200px;display: flex;align-items: center;justify-content: center;">
            <a-typography-title :level="4" style="color: #fff;"></a-typography-title>
          </div>
        </a-col> -->
      </a-row>
    </div>
  </div>
  <div class="download-top">
    <div style="width: 75%;">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="全部">
          <a-row  :gutter="[15,15]">
            <a-col :span="6" >
              <a-card title="苹果APP下载">
                <template #extra><AppleFilled style="color: #DE7A44;"/></template>
                <div style="text-align: center;">
                  <p><img src="../assets/imgs/down/ios_app.png" height="120px"></p>
                  <p><a>扫一扫安装苹果APP</a></p>
                </div>
              </a-card>
            </a-col>
            <a-col :span="6" >
              <a-card title="安卓APP下载">
                <template #extra><AndroidFilled style="color: #DE7A44;"/></template>
                <div style="text-align: center;">
                  <p><img src="../assets/imgs/down/android_app.png" height="120px"></p>
                  <p><a>扫一扫安装安卓APP</a></p>
                </div>
              </a-card>
              </a-col>
              <a-col :span="6" >
                <a-card title="谷歌浏览器下载">
                <template #extra><ChromeFilled style="color: #DE7A44;"/></template>
                <div style="text-align: left;height: 170px;">
                  <p><a href="http://www.gdycloud.com/down/Chrome_xp.rar" target="_break">【微软XP系统版本】点击下载</a></p>
                  <p><a href="http://www.gdycloud.com/down/Chrome_win7.rar" target="_break">【微软win7系统版本】点击下载</a></p>
                  <p><a href="http://www.gdycloud.com/down/Chrome_win10.rar" target="_break">【微软win10系统版本】点击下载</a></p>
                </div>
              </a-card>
              </a-col>
              <!-- <a-col :span="6" >
                <a-card title="火狐浏览器下载">
                <template #extra><ChromeFilled  style="color: #DE7A44;"/></template>
                <div style="text-align: left;height: 170px;">
                  <p><a>【微软XP系统版本】点击下载</a></p>
                  <p><a>【微软win7系统版本】点击下载</a></p>
                  <p><a>【微软win10系统版本】点击下载</a></p>
                </div>
              </a-card>
              </a-col> -->
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="2" tab="移动APP" force-render>
          <a-row  :gutter="[5,5]">
            <a-col :span="6" >
              <a-card title="苹果APP下载">
                <template #extra><AppleFilled style="color: #1F39A9;"/></template>
                <div style="text-align: center;">
                  <p><img src="../assets/imgs/down/ios_app.png" height="120px"></p>
                  <p><a>扫一扫安装苹果APP</a></p>
                </div>
              </a-card>
            </a-col>
            <a-col :span="6" >
              <a-card title="安卓APP下载">
                <template #extra><AndroidFilled style="color: #1F39A9;"/></template>
                <div style="text-align: center;">
                  <p><img src="../assets/imgs/down/android_app.png" height="120px"></p>
                  <p><a>扫一扫安装安卓APP</a></p>
                </div>
              </a-card>
              </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="3" tab="浏览器" force-render>
          <a-row  :gutter="[5,5]">
            <a-col :span="6" >
              <a-card title="谷歌浏览器下载">
                <template #extra><ChromeFilled style="color: #DE7A44;"/></template>
                <div style="text-align: left;height: 170px;">
                  <p><a href="http://www.gdycloud.com/down/Chrome_xp.rar" target="_break">【微软XP系统版本】点击下载</a></p>
                  <p><a href="http://www.gdycloud.com/down/Chrome_win7.rar" target="_break">【微软win7系统版本】点击下载</a></p>
                  <p><a href="http://www.gdycloud.com/down/Chrome_win10.rar" target="_break">【微软win10系统版本】点击下载</a></p>
                </div>
              </a-card>
            </a-col>
            <!-- <a-col :span="6" >
              <a-card title="火狐浏览器下载">
              <template #extra><ChromeFilled  style="color: #1F39A9;"/></template>
              <div style="text-align: left;height: 170px;">
                <p><a>【微软XP系统版本】点击下载</a></p>
                <p><a>【微软win7系统版本】点击下载</a></p>
                <p><a>【微软win10系统版本】点击下载</a></p>
              </div>
            </a-card>
            </a-col> -->
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>

  <div style="width: 100%;height: 280px;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #fff;">
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import {
  AppleFilled,AndroidFilled,ChromeFilled
} from '@ant-design/icons-vue';
const activeKey = ref('1');
const listData: Record<string, string>[] = [];

for (let i = 0; i < 23; i++) {
  listData.push({
    href: 'https://www.antdv.com/',
    title: `ant design vue part ${i}`,
    avatar: 'https://joeschmoe.io/api/v1/random',
    description:
      'Ant Design, a design language for background applications, is refined by Ant UED Team.',
    content:
      'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
  });
}
</script>
<style scoped>
.download-top{
  margin-top: 2px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color:#fff;
  /* width: 100%;height: 180px;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #fff; */
}
.price-top{
  height: 200px;width: 100%;text-align: center;
  display: flex;align-items: center;justify-content: center;
  background: #1F39A9;
  color:#fff;
  /* width: 100%;height: 180px;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #fff; */
}
</style>
