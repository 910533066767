import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home-top" }
const _hoisted_2 = { style: {"width":"75%"} }
const _hoisted_3 = { style: {"height":"150px"} }
const _hoisted_4 = { class: "components-input-demo-presuffix" }
const _hoisted_5 = { style: {"height":"40px","width":"100%","text-align":"center","display":"flex","align-items":"center","justify-content":"center","margin-top":"2px","background-color":"#fff"} }
const _hoisted_6 = { style: {"width":"75%"} }
const _hoisted_7 = { style: {"height":"30px","display":"flex","align-items":"center","justify-content":"center"} }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { style: {"width":"100%","text-align":"center","display":"flex","align-items":"center","justify-content":"center","margin-top":"2px","background-color":"#fff"} }
const _hoisted_10 = { style: {"width":"75%","margin-top":"30px","margin-bottom":"30px"} }
const _hoisted_11 = { style: {"height":"80px"} }
const _hoisted_12 = { style: {"height":"200px","width":"100%","color":"#000"} }
const _hoisted_13 = { class: "gutter-box" }
const _hoisted_14 = { style: {"height":"60px","display":"flex","align-items":"center","justify-content":"center","font-weight":"800","font-size":"16px"} }
const _hoisted_15 = { style: {"height":"60px","display":"flex","align-items":"center","justify-content":"center","margin-left":"15px","margin-right":"15px","line-height":"25px"} }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { style: {"width":"100%","text-align":"center","display":"flex","align-items":"center","justify-content":"center","background-color":"#fff"} }
const _hoisted_18 = { style: {"width":"75%","margin-bottom":"30px"} }
const _hoisted_19 = { style: {"height":"80px"} }
const _hoisted_20 = { style: {"height":"200px","width":"100%","color":"#000"} }
const _hoisted_21 = { class: "gutter-box" }
const _hoisted_22 = { style: {"height":"60px","display":"flex","align-items":"center","justify-content":"center","font-weight":"800","font-size":"16px"} }
const _hoisted_23 = { style: {"height":"60px","display":"flex","align-items":"center","justify-content":"center","margin-left":"15px","margin-right":"15px","line-height":"25px"} }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { style: {"width":"100%","height":"120px","text-align":"center","display":"flex","align-items":"center","justify-content":"center","background-color":"#F5F5F5"} }
const _hoisted_26 = { style: {"width":"75%"} }
const _hoisted_27 = { style: {"height":"60px"} }
const _hoisted_28 = { style: {"width":"100%","height":"180px","text-align":"center","display":"flex","align-items":"center","justify-content":"center"} }
const _hoisted_29 = { style: {"width":"75%"} }
const _hoisted_30 = ["src"]
const _hoisted_31 = { style: {"text-align":"center"} }
const _hoisted_32 = { style: {"text-align":"center"} }
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = { style: {"text-align":"center"} }

import { ref,reactive } from 'vue';
import {applyUserFun,getDataToHomeFun} from '../api/api.js';
import { UserOutlined,PhoneOutlined,SoundFilled,SafetyCertificateFilled,RocketFilled,TabletFilled } from '@ant-design/icons-vue';
import { NotificationPlacement, notification } from 'ant-design-vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  setup(__props) {

const userName = ref();
const userPhone = ref();
const resultStr = reactive([]);
const nuList = reactive([]);
const saleList = reactive([]);
const assList = reactive([]);
const bviewUrl = ref();
const funModel = ref();
const docBody = ref();
const open = ref<boolean>(false);
const showModal = (videoUrl) => {
  bviewUrl.value = videoUrl;
  open.value = true;
};
const handleOk = () => {
  open.value = false;
};
const [api,contextHolder] = notification.useNotification();
const openMessage = (placement: NotificationPlacement) => openNotification(placement);
const openNotification = (placement: NotificationPlacement) => {
  api.info({
    message: `提醒`,
    description:
      '请输入11位手机号和姓名，方便我们及时与您联系！',
    placement,
  });
};
const openApply = ref<boolean>(false);
const showApplyModal = () => {
  openApply.value = true;
};
const handleOkApply = () => {
  openApply.value = false;
};
getDataToHome();
function getDataToHome(){
  getDataToHomeFun().then((res) => {
    if(res.code=='200'){
      let nuArry = res.result.nuList;
      for(let i=0;i<nuArry.length;i++){
        nuList.push(nuArry[i]);
      }
      //销售核心功能
      let saArry = res.result.saleList;
      for(let i=0;i<saArry.length;i++){
        saleList.push(saArry[i]);
      }
      //售后核心功能
      let asArry = res.result.assList;
      for(let i=0;i<asArry.length;i++){
        assList.push(asArry[i]);
      }
    }
  });
}
function applyUser(){
  if(userName.value!=null && userPhone.value!=null){
    if(userPhone.value.length != 11){
      openMessage('topRight');
    }else{
      // let res = applyUserFun(userName.value,userPhone.value);
      applyUserFun(userName.value,userPhone.value).then((res) => {
          if(res.result=='200'){
            resultStr.length = 0;
            resultStr.push("申请成功，可使用以下账号进行试用!");
            resultStr.push("角色/账号/密码，店总/eshoptest/000000");
            resultStr.push("试用过程中，如果有任何问题，请及时和我们联系！");
            showApplyModal();
          }
      });
    }
  }else{
    openMessage('topRight');
  }
}
const openDocView = ref<boolean>(false);
const showDocView = (obj) => {
  funModel.value = obj.funModel;
  docBody.value = obj.funRemark;
  openDocView.value = true;
};
function handleOkDocView(){
  openDocView.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_carousel = _resolveComponent("a-carousel")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 12,
              style: {"display":"flex","align-items":"center","justify-content":"center"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_a_typography_title, {
                    level: 2,
                    style: {"color":"#fff"}
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("助力商用车经销商数字化转型升级")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_typography_title, {
                    level: 5,
                    style: {"color":"#fff"}
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("云端轻松实现销售、售后、财务等全业务流程数字化！")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              span: 12,
              style: {"display":"flex","align-items":"center","justify-content":"center"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_a_card, {
                    title: "申请免费体验",
                    bordered: false,
                    style: {"width":"300px","font-size":"16px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_a_input, {
                          value: userPhone.value,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((userPhone).value = $event)),
                          placeholder: "请输入您的手机号",
                          style: {"height":"40px"}
                        }, {
                          prefix: _withCtx(() => [
                            _createVNode(_unref(PhoneOutlined))
                          ]),
                          _: 1
                        }, 8, ["value"]),
                        _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                        _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_a_input, {
                          value: userName.value,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((userName).value = $event)),
                          placeholder: "请输入您的姓名",
                          style: {"height":"40px"}
                        }, {
                          prefix: _withCtx(() => [
                            _createVNode(_unref(UserOutlined))
                          ]),
                          _: 1
                        }, 8, ["value"]),
                        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                        _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_a_button, {
                          type: "ant-btn ant-btn-primary ant-btn-large ant-btn-block",
                          onClick: applyUser,
                          style: {"background-color":"#1F39A9","height":"45px"}
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createElementVNode("span", { style: {"font-size":"14px","font-weight":"800"} }, "申请试用", -1)
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 1 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_unref(SoundFilled), { style: {"size":"14px","color":"#1F39A9"} })
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              span: 23,
              style: {"text-align":"left","font-size":"13px"}
            }, {
              default: _withCtx(() => [
                (nuList.length>0)
                  ? (_openBlock(), _createBlock(_component_a_carousel, {
                      key: 0,
                      autoplay: "",
                      "dot-position": "left",
                      dots: false
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nuList, (item) => {
                          return (_openBlock(), _createElementBlock("span", { key: item }, [
                            _createElementVNode("a", {
                              href: "#",
                              onClick: ($event: any) => (showDocView(item))
                            }, _toDisplayString(item.funModel), 9, _hoisted_8)
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_a_typography_title, {
                    level: 5,
                    style: {"color":"#1F39A9","font-size":"24px","font-weight":"800"}
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("核心功能-销售业务")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_row, { gutter: [30,30] }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(saleList, (item) => {
              return (_openBlock(), _createBlock(_component_a_col, {
                class: "gutter-row",
                span: 6,
                key: item
              }, {
                default: _withCtx(() => [
                  _createElementVNode("a", null, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_14, _toDisplayString(item.funModel), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_15, _toDisplayString(item.remark), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 16 }),
                            (item.videoUrl != null && item.videoUrl != '' )
                              ? (_openBlock(), _createBlock(_component_a_col, {
                                  key: 0,
                                  span: 8
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      style: {"height":"80px","font-size":"13px","display":"flex","align-items":"center","justify-content":"center","text-align":"right","font-weight":"800"},
                                      onClick: ($event: any) => (showModal(item.videoUrl))
                                    }, " 功能演示 ", 8, _hoisted_16)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ])
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_a_typography_title, {
                    level: 5,
                    style: {"color":"#1F39A9","font-size":"24px","font-weight":"800"}
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode("核心功能-售后业务")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_row, { gutter: [30,30] }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assList, (item) => {
              return (_openBlock(), _createBlock(_component_a_col, {
                class: "gutter-row",
                span: 6,
                key: item
              }, {
                default: _withCtx(() => [
                  _createElementVNode("a", null, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_22, _toDisplayString(item.funModel), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_23, _toDisplayString(item.remark), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 16 }),
                            (item.videoUrl != null && item.videoUrl != '' )
                              ? (_openBlock(), _createBlock(_component_a_col, {
                                  key: 0,
                                  span: 8
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      style: {"height":"80px","font-size":"13px","display":"flex","align-items":"center","justify-content":"center","text-align":"right","font-weight":"800"},
                                      onClick: ($event: any) => (showModal(item.videoUrl))
                                    }, " 功能演示 ", 8, _hoisted_24)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ])
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("div", _hoisted_26, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_27, [
                  _createVNode(_component_a_typography_title, {
                    level: 5,
                    style: {"color":"#1F39A9","font-size":"24px","font-weight":"800"}
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode("产品特点")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_28, [
      _createElementVNode("div", _hoisted_29, [
        _createVNode(_component_a_row, { gutter: [15,15] }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 8 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_card, { title: "安全性" }, {
                  extra: _withCtx(() => [
                    _createVNode(_unref(SafetyCertificateFilled), { style: {"color":"#DE7A44"} })
                  ]),
                  default: _withCtx(() => [
                    _cache[15] || (_cache[15] = _createElementVNode("div", { style: {"text-align":"center","height":"100px","font-size":"16px"} }, " 云端存储、数据加密、分级授权 ", -1))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 8 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_card, { title: "便捷性" }, {
                  extra: _withCtx(() => [
                    _createVNode(_unref(TabletFilled), { style: {"color":"#DE7A44"} })
                  ]),
                  default: _withCtx(() => [
                    _cache[16] || (_cache[16] = _createElementVNode("div", { style: {"text-align":"center","height":"100px","font-size":"16px"} }, " 多端数据互通，业务操作直观简洁 ", -1))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 8 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_card, { title: "高效性" }, {
                  extra: _withCtx(() => [
                    _createVNode(_unref(RocketFilled), { style: {"color":"#DE7A44"} })
                  ]),
                  default: _withCtx(() => [
                    _cache[17] || (_cache[17] = _createElementVNode("div", { style: {"text-align":"center","height":"100px","font-size":"16px"} }, " 移动APP快捷办理、数据只能分析 ", -1))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_a_modal, {
      open: open.value,
      "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((open).value = $event)),
      title: "功能视频演示",
      maskClosable: false,
      width: "850px",
      cancelText: "关闭",
      closable: false,
      footer: null
    }, {
      default: _withCtx(() => [
        _createElementVNode("iframe", {
          src: bviewUrl.value,
          scrolling: "no",
          border: "0",
          frameborder: "no",
          framespacing: "0",
          allowfullscreen: "true",
          width: "800px",
          height: "450px"
        }, null, 8, _hoisted_30),
        _createElementVNode("div", _hoisted_31, [
          _createVNode(_component_a_button, {
            type: "primary",
            style: {"background-color":"#1F39A9"},
            onClick: handleOk
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createElementVNode("span", { style: {"font-size":"14px","font-weight":"800"} }, "关闭", -1)
            ])),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["open"]),
    _createVNode(_component_a_modal, {
      open: openApply.value,
      "onUpdate:open": _cache[3] || (_cache[3] = ($event: any) => ((openApply).value = $event)),
      title: "申请试用成功",
      maskClosable: false,
      width: "850px",
      cancelText: "关闭",
      closable: false,
      footer: null
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(resultStr, (item) => {
          return (_openBlock(), _createElementBlock("div", { key: item }, [
            _createElementVNode("p", null, _toDisplayString(item), 1)
          ]))
        }), 128)),
        _cache[20] || (_cache[20] = _createElementVNode("div", null, [
          _createElementVNode("a", {
            href: "https://www.gdyapp.com/eshop/",
            target: "_break"
          }, " 点击试用")
        ], -1)),
        _createElementVNode("div", _hoisted_32, [
          _createVNode(_component_a_button, {
            type: "primary",
            style: {"background-color":"#1F39A9"},
            onClick: handleOkApply
          }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [
              _createElementVNode("span", { style: {"font-size":"14px","font-weight":"800"} }, "关闭", -1)
            ])),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["open"]),
    _createVNode(_component_a_modal, {
      open: openDocView.value,
      "onUpdate:open": _cache[4] || (_cache[4] = ($event: any) => ((openDocView).value = $event)),
      title: "动态详情",
      maskClosable: false,
      width: "850px",
      cancelText: "关闭",
      closable: false,
      footer: null
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_a_divider),
          _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("h4", null, _toDisplayString(funModel.value), 1),
          _createElementVNode("div", { innerHTML: docBody.value }, null, 8, _hoisted_33)
        ]),
        _createElementVNode("div", _hoisted_34, [
          _createVNode(_component_a_button, {
            type: "primary",
            style: {"background-color":"#1F39A9"},
            onClick: handleOkDocView
          }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [
              _createElementVNode("span", { style: {"font-size":"14px","font-weight":"800"} }, "关闭", -1)
            ])),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["open"]),
    _createVNode(_unref(contextHolder))
  ], 64))
}
}

})