<template>
  <div class="price-top" >
    <div style="width: 75%;">
      <a-row>
        <a-col :span="12">
          <div style="height: 200px;display: flex;align-items: center;justify-content: center;">
            <a-typography-title :level="4" style="color: #fff;">最优惠的价格，提供最好的产品服务</a-typography-title>
          </div>
        </a-col>
        <a-col :span="12">
          <div style="height: 180px;display: flex;align-items: center;justify-content: center;">
             <img src="../assets/imgs/price/price_top.svg" height="120px">
          </div>
        </a-col>
      </a-row>
    </div>
  </div>

  <div style="width: 100%;height: 80px;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #F5F5F5;">
    <div style="width: 75%;">
      <a-row >
        <a-col :span="10" >
          <div style="text-align: left;height: 80px;display: flex;align-items: center;">
            <a-typography-title :level="4" style="color: #1F39A9;">产品&服务内容&报价</a-typography-title>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
  <div style="width: 100%;height: 350px;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #F5F5F5;">
    <div style="width: 75%;background-color: #fff;  border-radius: 5px;">
        <a-row>
          <a-col :span="24" >
            <div style="background-color: #fff;align-items: center;justify-content: center;">
              <a-table :columns="columns" size="large" :data-source="data" :pagination="false" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
              </a-table>
            </div>
          </a-col>
      </a-row>
    </div>
  </div>

  <div style="width: 100%;height: 80px;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #F5F5F5;">
    <div style="width: 75%;">
      <a-row >
        <a-col :span="4" >
          <div style="text-align: left;height: 80px;display: flex;align-items: center;">
            <a-typography-title :level="4" style="color: #1F39A9;">开通流程</a-typography-title>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
  <div style="width: 100%;height: 130px;text-align: center;display: flex;align-items: center;justify-content: center;">
    <div style="width: 75%;background-color: #fff;border-radius: 5px;">
      <a-row >
        <a-col :span="24" >
          <div style="text-align: left;margin: 30px 30px 30px 30px;">
            <a-steps
              size="default"
              :current="6"
              :items="[
                {
                  title: '免费试用',
                  description:'系统初步试用',
                  status: 'finish',
                  icon: h(AppstoreFilled),
                },
                {
                  title: '签订合同',
                  // description:'保障双方权益',
                  status: 'finish',
                  icon: h(FileTextFilled),
                },
                {
                  title: '汇款',
                  description:'',
                  status: 'finish',
                  icon: h(MoneyCollectFilled),
                },
                {
                  title: '开通账号',
                  description:'分配正式账号',
                  status: 'finish',
                  icon: h(IdcardFilled),
                },
                {
                  title: '在线培训',
                  description:'',
                  status: 'finish',
                  icon: h(ReadFilled),
                },
                {
                  title: '远程支持',
                  description:'',
                  status: 'finish',
                  icon: h(PhoneFilled),
                }
              ]"
            ></a-steps>
          </div>
        </a-col>
      </a-row>
      <!-- <a-row >
        <a-col :span="4" >
        </a-col>
        <a-col :span="4" >
          <div style="height: 40px;display: flex;align-items: center;">
            <div style="text-align: center;margin-left: 45px;font-weight: 800;">
              <a>点击查看合同</a>
            </div>
          </div>
        </a-col>
      </a-row> -->
    </div>
  </div>
  <div style="width: 100%;height: 80px;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #F5F5F5;">
    <div style="width: 75%;">
      <a-row >
        <a-col :span="4" >
          <div style="text-align: left;height: 80px;display: flex;align-items: center;">
            <a-typography-title :level="4" style="color: #1F39A9;">汇款账号</a-typography-title>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
  <div style="width: 100%;height: 150px;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #F5F5F5;">
    <div style="width: 75%;background-color: #fff;border-radius: 5px;">
        <a-row>
          <a-col :span="6" >
            <div style="background-color: #fff;height: 150px;display: flex;align-items: center;justify-content: center;">
              <img src="../assets/imgs/price/icbc.svg">
            </div>
          </a-col>
          <a-col :span="16" >
            <div style="background-color: #fff;height: 150px;display: grid;background-color: #fff;font-weight: 800;">
              <div style="width: 100%;text-align: center;display: flex;align-items: center;justify-content: right;">
                开户银行：中国工商银行股份有限公司无锡梁溪支行
              </div>
              <div style="width: 100%;text-align: center;display: flex;align-items: center;justify-content: right;">
                开户名称：谷得一软件科技无锡有限公司
              </div>
              <div style="width: 100%;text-align: center;display: flex;align-items: center;justify-content: right;">
                开户账号：1103020109200913465
              </div>
            </div>
          </a-col>
      </a-row>
    </div>
  </div>
  <div style="width: 100%;height: 150px;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #F5F5F5;margin-top: 10px;">
    <div style="width: 75%;background-color: #fff;border-radius: 5px;">
        <a-row>
          <a-col :span="6" >
            <div style="background-color: #fff;height: 150px;display: flex;align-items: center;justify-content: center;">
              <img src="../assets/imgs/price/zfb.svg">
            </div>
          </a-col>
          <a-col :span="16" >
            <div style="background-color: #fff;height: 150px;display: grid;background-color: #fff;font-weight: 800;">
              <div style="width: 100%;text-align: center;display: flex;align-items: center;justify-content: right;">
                &nbsp;
              </div>
              <div style="width: 100%;text-align: center;display: flex;align-items: center;justify-content: right;">
                支付宝账号：15900827045
              </div>
              <div style="width: 100%;text-align: center;display: flex;align-items: center;justify-content: right;">
                支付宝姓名：高振义
              </div>
            </div>
          </a-col>
      </a-row>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { h } from 'vue';
import {
  AppstoreFilled,
  FileTextFilled,
  MoneyCollectFilled,
  IdcardFilled,
  PhoneFilled,
  ReadFilled
} from '@ant-design/icons-vue';

const columns = [
  {
    title: '项目',
    dataIndex: 'item',
    key: 'item',
    align:'center'
  },
  {
    title: '1年价格/优惠价格',
    dataIndex: 'price1',
    key: 'price1',
    align:'center'
  },
  {
    title: '2年价格/优惠价格',
    dataIndex: 'price2',
    key: 'price2',
    align:'center'
  },
  {
    title: '3年价格/优惠价格',
    key: 'price3',
    dataIndex: 'price3',
    align:'center'
  },
  {
    title: '4年价格/优惠价格',
    dataIndex:'price4',
    key: 'price4',
    align:'center'
  },
  {
    title: '5年价格/优惠价格',
    dataIndex:'price5',
    key: 'price5',
    align:'center'
  },
];

const data = [
  {
    item: '销售业务',
    price1: '￥500 / ￥400',
    price2: '￥1000 / ￥800',
    price3: '￥1500 / ￥1200',
    price4: '￥2000 / ￥1600',
    price5: '￥2500 / ￥2000',
  },
  {
    item: '售后业务',
    price1: '￥500 / ￥400',
    price2: '￥1000 / ￥800',
    price3: '￥1500 / ￥1200',
    price4: '￥2000 / ￥1600',
    price5: '￥2500 / ￥2000',
  },
  {
    item: '销售+售后业务',
    price1: '￥1000 / ￥800',
    price2: '￥2000 / ￥1600',
    price3: '￥3000 / ￥2400',
    price4: '￥4000 / ￥3200',
    price5: '￥5000 / ￥4000',
  },
  {
    item: '移动APP',
    price1: '免费',
    price2: '免费',
    price3: '免费',
    price4: '免费',
    price5: '免费',
  },
  {
    item: '7*12小时远程服务',
    price1: '免费',
    price2: '免费',
    price3: '免费',
    price4: '免费',
    price5: '免费',
  },
];
</script>
<style scoped>
.price-top{
  height: 200px;width: 100%;text-align: center;
  display: flex;align-items: center;justify-content: center;
  background: #1F39A9;
  color:#fff;
  /* width: 100%;height: 180px;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #fff; */
}
</style>
