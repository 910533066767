import { request } from "../utils/request.js";

/** 申请试用
 * @param {
 * token: "登录token",
 * }
 */
export function applyUserFun(userName,userPhone) {
  // let param = dataToParam(data);
  return request({
    url: "/system/web/applyUserToWeb?userName="+userName+"&userPhone="+userPhone,
    data: null,
  });
}


export function getDataToHomeFun() {
  return request({
    url: "/system/web/getDataToHome",
    data: null,
  });
}

export function getAllUpdataDataToFun() {
  return request({
    url: "/system/web/getAllUpdataData",
    data: null,
  });
}

export function getAllNewDataToFun() {
  return request({
    url: "/system/web/getAllNewData",
    data: null,
  });
}